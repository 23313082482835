@import "../mixins/media";

.c-sectionTitle {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 11px;
  line-height: 1;
  p {
    font-family: Inter;
  }
  @include media_desktop {
    flex-direction: row;
  }
}
.c-sectionTitle__en {
  font-size: clamp(2rem, 0.313rem + 3.52vw, 3.125rem);
  color: #3b3e41;
  text-transform: uppercase;
  @include media_desktop {
    font-size: 50px;
  }
}
.c-sectionTitle__ja {
  color: #3b3e41;
  font-size: clamp(0.75rem, 0.631rem + 0.51vw, 0.875rem);
  @include media_desktop {
    font-size: 14px;
  }
}
