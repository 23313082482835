@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: #008542;

  @include media_desktop() {
    padding-top: 40px;
    margin-top: 100px;
  }
  & &__inner {
    padding-top: 40px;
    @include container;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link {
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 40px 0 42px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 50px 0 42px;
  }

  & &__logo {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a {
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }
    }

    &:hover {
      a {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
  & &__copyright {
    font-size: 12px;

    @include media_desktop {
      font-size: 15px;
    }
  }
}

.p-footerRole__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @include media_desktop {
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
  }
}

.p-footerRole__navWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-top: 60px;
  gap: 50px;
  display: none;
  @include media_desktop {
    display: flex;
  }
}

.p-footerRole__navTitle {
  color: #fff;
  font-size: clamp(1.125rem, 0.75rem + 0.78vw, 1.375rem);
  font-weight: 600;
}

.p-footerRole__nav {
  margin-left: 22px;
  list-style: none;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: auto;
  padding: 0;
}

.p-footerRole__navLink {
  color: #fff;
  font-size: clamp(0.875rem, 0.688rem + 0.39vw, 1rem);
  &:hover {
    color: #f9b700;
  }
}

.p-footerRole__navLink--fontLarge {
  font-size: clamp(1rem, 0.625rem + 0.78vw, 1.25rem);
  font-weight: 600;
}
