@import "../mixins/media";

.c-btn.c-btn {
  border-radius: 999px;
  font-family: Inter;
  font-size: clamp(1rem, 0.881rem + 0.51vw, 1.125rem);
  font-weight: 600;
  padding: 17px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
  @include media_desktop {
    font-size: 18px;
  }
  &--green {
    background: #008542;
    color: #fff;
  }
  &--white {
    background: #fff;
    color: #008542;
  }
  &:hover {
    opacity: 0.7;
  }

  img {
    width: 15px !important;
    height: 15px !important;
  }
}
