@import "../mixins/media";

.l-home-payment {
  margin-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  @include media_desktop {
    margin-top: 184px;
  }
}
.p-home-payment__lead {
  margin-top: 30px;
  line-height: 2.4;
  font-size: 14px;
}
.p-home-payment__itemsWrapper {
  margin-top: 48px;
  border-radius: 20px;
  background: #f5f5f5;
  padding: 24px;
}
.p-home-payment__items {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  @include media_desktop {
    gap: 50px;
  }
}
.p-home-payment__name {
  text-align: center;
  font-size: 14px;
}
.p-home-payment__image {
  margin-top: 6px;
  max-width: 100%;
}
