@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/

.ec-newItemRole {
  padding: 40px 0;

  @include media_desktop {
    padding: 60px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: nowrap;
    }
  }
  & &__listItem {
    border-radius: 8px;
    border: 2px solid #3b3e41;
    background: #fff;
    margin-bottom: 4%;
    width: 48%;
    height: auto;

    &:not(:first-child) {
      a {
        color: black;
      }
    }

    @include media_desktop {
      margin-bottom: 15px;
      width: calc(100% / 3);

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }

    &:nth-child(odd) {
      margin-right: 4%;

      @include media_desktop {
        margin-right: 30px;
      }
    }
  }
  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }
  & &__listItemTitle {
    margin: 8px 0;
    font-size: 14px;
    font-weight: bold;

    @include media_desktop {
      margin: 20px 0 10px;
    }
  }

  & &__listItemPrice {
    font-size: 12px;
  }
}

// TOPページのnew itemsセクション
.l-btnNewItem {
  margin: 48px auto 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.l-ec-newItemRole__list--top {
  margin-top: 16px;
}
.p-ec-newItemRole__link {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
  padding: 15px;
  @include media_desktop {
    flex-direction: row;
  }
}
.p-ec-newItemRole__link:hover {
  opacity: 0.7;
}
.p-ec-newItemRole__image {
  width: 100%;
  height: 200px;
  img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  @include media_desktop {
    width: 40%;
  }
}
