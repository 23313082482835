@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/

.ec-categoryRole {
  padding: 40px 0;
  color: black;
  // background: #F8F8F8;

  @include media_desktop {
    padding: 60px 0;
  }

  & &__list {
    padding: 72px 0 64px;
    background: #d9d9d9;
    width: 100vw;
    margin: 0 calc(50% - 50vw);

    @include media_desktop {
      flex-wrap: nowrap;
    }
  }
  & &__listItem {
    width: 45%;
    max-width: 220px;
    height: auto;
    border-radius: 8px;
    border: 4px solid transparent;
    background: #fff;
    transition: 0.5s;
    &:hover {
      border: 4px solid #008542;
    }
    a {
      display: block;
      padding: 20px 0 50px;
    }
    img {
      margin-top: 40px;
    }
  }
}
.ec-categoryRole__inner {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  padding-left: 20px;
  padding-right: 20px;
  @include media_desktop {
    padding: 0;
  }
}
.ec-categoryRole__title {
  color: #3b3e41;
  font-family: Inter;
  font-size: clamp(1rem, 0.881rem + 0.51vw, 1.125rem);
  font-style: normal;
  font-weight: 600;
  text-align: center;
  @include media_desktop {
    font-size: 18px;
  }
}
.l-btnCategory {
  margin: 64px auto 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.l-home-category {
  margin-top: 80px;
  padding-top: 0;
  @include media_desktop {
    margin-top: 184px;
  }
}
